<template>
  <div>
    <p-card class="overflow-hidden">
      <template #header>
        <img :src="getRandomAsset('marketingImages')" style="max-width: 15rem;">
      </template>
      <template #title>
        <span class="ad">Ad</span>
        {{getRandomAsset('headlines')}}
      </template>
      <template #subtitle>{{getRandomAsset('descriptions')}}</template>
    </p-card>
  </div>
</template>

<script>
export default {
  name: 'adPreviewDiscovery',
  props: ['creativePlan'],
  methods: {
    getAsset (assetKey) {
      let matchingAssets = []
      // return Array.from(this.creativePlan.adSpec.textAssets)
      Object.keys(this.creativePlan?.adSpec || {}).forEach(assetType => {
        // console.log(this.creativePlan.adSpec[assetType])
        this.creativePlan.adSpec[assetType].forEach(asset => {
          if (asset.locationInAd === assetKey) {
            matchingAssets.push(asset.text || asset.path)
          }
        })
      })
      // handle case where ad spec is singular instead of plural i.e. headline vs headlines
      if (assetKey.endsWith('s') && matchingAssets.length === 0) {
        matchingAssets = this.getAsset(assetKey.substring(0, assetKey.length - 1))
      }
      return matchingAssets
    },
    getRandomAsset (assetKey) {
      const matchingAssets = this.getAsset(assetKey)
      const max = matchingAssets.length - 1
      const min = 0
      const randomIndex = Math.floor(Math.random() * (max - min + 1)) + min
      return matchingAssets[randomIndex]
    }
  }
}
</script>

<style scoped>
.special-card.card {
  border-top:0;
}
.special-card {
  box-shadow: 3px 3px 5px 2px #ccc;
}

.ad {
  font-size: 80%;
}

.ad {
  background-color: #fff;
  border-radius: 3px;
  color: #006621;
  font-size: 11px;
  border: 1px solid #006621;
  padding: 1px 3px 0 2px;
  line-height: 11px;
  vertical-align: baseline;
}
</style>
