<template>
  <div>
    <ad-preview-discovery :creative-plan="creativePlan" :show-image="!smaller"></ad-preview-discovery>
  </div>
</template>

<script>
import AdPreviewDiscovery from './adPreviewDiscovery'

export default {
  name: 'adPreviewPerformanceMax',
  components: { AdPreviewDiscovery },
  props: ['creativePlan', 'smaller', 'extensionOptions']
}
</script>

<style scoped>

</style>
