<template>
  <div v-if="vastSource.url">
    <div v-if="vastSource.type.includes('video')">
      <video controls height="100%" width="100%">
        <source :type="vastSource.type"
                :src="vastSource.url"/>
      </video>
    </div>
    <div v-else-if="vastSource.type.includes('audio')">
      <audio controls>
        <source :type="vastSource.type"
                :src="vastSource.url" />
      </audio>
    </div>
  </div>
  <p v-else class="alert alert-warning p-2">Unable to generate Ad Preview.</p>
</template>

<script>
import axios from 'axios'

export default {
  name: 'adPreviewVastSource',
  props: ['creativePlan'],
  data () {
    return {
      vastSource: {}
    }
  },
  watch: {
    vastUrl: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.fetchVideoOrAudioUrl(newVal)
        }
      }
    }
  },
  computed: {
    vastUrl () {
      const url = this.creativePlan?.adSpec?.textAssets?.find(a => a.locationInAd === 'body')?.text
      if (url) { // replace {{}}, ${}, %% macros with random string text so the url resolves
        const replaced = url
          .replace(/%%(.*?)%%/g, 'macro')
          .replace(/\${(.*?)}/g, 'macro')
          .replace(/\[(.*?)\]/g, 'macro')

        return replaced
      }
      return false
    }
  },
  methods: {
    async fetchVideoOrAudioUrl () {
      try {
        const res = await axios.get(this.vastUrl, { responseType: 'text' })
        if (res.status !== 200) throw new Error('Not valid URL')

        const parser = new DOMParser()
        const xml = parser.parseFromString(res.data, 'text/xml')
        const vastTag = xml.documentElement
        if (!vastTag.nodeName.toLowerCase() === 'vast') throw new Error('Not a valid VAST xml doc')

        const descendantMediaTags = vastTag.querySelectorAll('MediaFile')

        const found = Array.from(descendantMediaTags).find(mediaFile => {
          const type = mediaFile.getAttribute('type')
          return type && (type.includes('audio') || type.includes('video'))
        })

        if (found) {
          this.vastSource = {
            type: found.getAttribute('type'),
            url: found.textContent
          }
        } else {
          throw new Error('No media tags found.')
        }
      } catch (error) {
        this.vastSource = {}
        console.error('Error loading VAST:', error)
      }
    }
  }
}
</script>
